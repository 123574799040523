/* eslint-disable jsx-a11y/no-distracting-elements */
import React from 'react';
import HeaderComponent from '../Header/HeaderComponent';
import FooterComponent from '../Footer/FooterComponent';
const UnderProcessComponent = () => {
    
  
    return (
        <>
        <HeaderComponent/>
        <div className="container-fluid page-header mb-5 py-5">
        <div className="container">
            <marquee scrollamount="15">
                <h1 className="display-3 text-white mb-3 animated slideInDown">Will be uploaded soon</h1>
            </marquee>
        </div>
    </div>

    
        <FooterComponent/>
        </>
    );
};

export default UnderProcessComponent;

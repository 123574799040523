import React from "react";
import HeaderComponent from "../Header/HeaderComponent";
import FooterComponent from "../Footer/FooterComponent";
const AssociateEditorsComponent = () => {
  return (
    <>
      <HeaderComponent activeLink={"editorial"}/>
      <div className="container-fluid page-header mb-5 py-5">
        <div className="container">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Associate Editors
          </h1>
        </div>
      </div>

      <div className="container-xxl py-5" style={{ marginRight: "auto" }}>
        <div
          className="container"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div
            className="text-center wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ display: "flex", justifyContent: "space-around" }}
          ></div>
        </div>
        <div className="row g-4" style={{ display: "flex" }}>
          <div
            className="col-lg-12 col-md-6 wow fadeInUp showGrid"
            data-wow-delay="0.1s"
          >
            <div className="team-item list">
              <div className="position-relative overflow-hidden">
                <img
                  className="img-fluid imageHeightWidth"
                  src="./img/editors/prithvi.jpg"
                  alt=""
                />
              </div>
              <div className="team-text new-team">
                <div className="bg-light-new">
                  <h5 className="fw-bold mb-0">
                    Dr. Prithiviraj Singh Chauhan
                  </h5>
                  <h6
                    style={{
                      color: "rgb(72, 111, 176)",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                    className="fw-bold mb-0"
                  >
                    Assistant Professor
                  </h6>
                  <h6
                    style={{
                      color: "rgb(72, 111, 176)",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                    className="fw-bold mb-0"
                  >
                    Department of English
                  </h6>
                  <h6
                    style={{
                      color: "rgb(72, 111, 176)",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                    className="fw-bold mb-0"
                  >
                    Deva Nagari College,
                  </h6>
                  <h6
                    style={{
                      color: "rgb(72, 111, 176)",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                    className="fw-bold mb-0"
                  >
                    Meerut, Uttara Pradesh, India
                  </h6>
                </div>
              </div>
            </div>
            <div className="team-item list">
              <div className="position-relative overflow-hidden">
                <img
                  className="img-fluid imageHeightWidth"
                  src="./img/editors/padmanabhan.jpg"
                  alt=""
                />
              </div>
              <div className="team-text new-team">
                <div className="bg-light-new">
                  <h5 className="fw-bold mb-0">Dr. B. Padmanabhan</h5>
                  <h6
                    style={{
                      color: "rgb(72, 111, 176)",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                    className="fw-bold mb-0"
                  >
                    Assistant Professor
                  </h6>
                  <h6
                    style={{
                      color: "rgb(72, 111, 176)",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                    className="fw-bold mb-0"
                  >
                    Department of English
                  </h6>
                  <h6
                    style={{
                      color: "rgb(72, 111, 176)",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                    className="fw-bold mb-0"
                  >
                    Bharathiar University,
                  </h6>
                  <h6
                    style={{
                      color: "rgb(72, 111, 176)",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                    className="fw-bold mb-0"
                  >
                    Coimbatore, Tamil Nadu, India.
                  </h6>
                </div>
              </div>
            </div>
            <div className="team-item list">
              <div className="position-relative overflow-hidden">
                <img
                  className="img-fluid imageHeightWidth"
                  src="./img/editors/valiur.jpg"
                  alt=""
                />
              </div>
              <div className="team-text new-team">
                <div className="bg-light-new">
                  <h5 className="fw-bold mb-0">Dr. Valiur Rahaman</h5>
                  <h6
                    style={{
                      color: "rgb(72, 111, 176)",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                    className="fw-bold mb-0"
                  >
                    Associate Professor
                  </h6>
                  <h6
                    style={{
                      color: "rgb(72, 111, 176)",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                    className="fw-bold mb-0"
                  >
                    Lovely Professional University,
                  </h6>
                  <h6
                    style={{
                      color: "rgb(72, 111, 176)",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                    className="fw-bold mb-0"
                  >
                    Jalandhar, Punjab, India.
                  </h6>
                </div>
              </div>
            </div>
            <div className="team-item list">
              <div className="position-relative overflow-hidden">
                <img
                  className="img-fluid imageHeightWidth"
                  src="./img/editors/ipsita.jpg"
                  alt=""
                />
              </div>
              <div className="team-text new-team">
                <div className="bg-light-new">
                  <h5 className="fw-bold mb-0">Dr. Ipsita Bhattacharyya</h5>
                  <h6
                    style={{
                      color: "rgb(72, 111, 176)",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                    className="fw-bold mb-0"
                  >
                    Assistant Professor
                  </h6>
                  <h6
                    style={{
                      color: "rgb(72, 111, 176)",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                    className="fw-bold mb-0"
                  >
                    Department of English
                  </h6>
                  <h6
                    style={{
                      color: "rgb(72, 111, 176)",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                    className="fw-bold mb-0"
                  >
                    DHSK Commerce College,
                  </h6>
                  <h6
                    style={{
                      color: "rgb(72, 111, 176)",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                    className="fw-bold mb-0"
                  >
                    Dibrugarh, Assam, India.
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterComponent />
    </>
  );
};

export default AssociateEditorsComponent;

import React from "react";
import HeaderComponent from "../Header/HeaderComponent";
import FooterComponent from "../Footer/FooterComponent";
const CallForPapersComponent = () => {
  return (
    <>
      <HeaderComponent activeLink={"submission"} />
      <div className="container-fluid page-header mb-5 py-5">
        <div className="container">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Call for Papers
          </h1>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">LC Vol 01 No 02 2025(July Issue)</h1>
              <p className="mb-4">
                LitCult is pleased to announce its call for papers for
                Vol 01 No 02, scheduled for publication in July 2025.
                LitCult is a Bi-Annual Peer-Reviewed Open Access e-Journal
                dedicated to fostering critical conversations and exploration of
                literature and culture from diverse perspectives. We invite
                scholars, researchers, and practitioners to contribute their
                original research articles, reviews, and critical essays to
                contribute to the vibrant discourse within the field of literary
                and cultural studies.
              </p>
            </div>
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Scope of the Journal:</h1>
              <p className="mb-4">
                LitCult welcomes submissions that explore a wide range of topics
                within the realms of literature and culture. We encourage
                interdisciplinary approaches and perspectives that engage with
                issues such as (but not limited to):
              </p>

                <ul>
                  <li>Literary analysis and criticism</li>
                  <li>Cultural studies</li>
                  <li>Comparative literature</li>
                  <li>Postcolonial studies</li>
                  <li>Gender and sexuality in literature</li>
                  <li>Film and media studies</li>
                  <li>Digital humanities and literature</li>
                  <li>Literary theory and methodology</li>
                  <li>Translation studies</li>
                  <li>Historical and social contexts of literature</li>
                </ul>
            </div>
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Submission Guidelines:</h1>
              <p className="mb-4">
                Manuscripts should be original and previously unpublished.
                Submissions must be written in English and formatted according
                to the LitCult style guide. (Please visit our website
                <a href="www.litcultjournal.com">www.litcultjournal.com</a> to
                access the submission guidelines and the online submission
                system). Manuscripts should be submitted at{" "}
                <a href="mailto:litcultajournal@gmail.com">
                  litcultajournal@gmail.com
                </a>{" "}
                All submissions will undergo a double-blind peer-review process.
              </p>
            </div>
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Important Dates: LC Vol 01 No 02 2025(July Issue)</h1>
              <p className="mb-4">
                Submission Deadline: March 31, 2025
                <br />
                Notification of Acceptance: First Week of June 2025 <br />
                Publication: First Week of July 2025
              </p>
            </div>
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">How to Submit:</h1>
              <p className="mb-4">
                Please email your paper along with the self-declaration and
                copyright forms to{" "}
                <a href="mailto:litcultajournal@gmail.com">
                  litcultajournal@gmail.com
                </a>
                . LitCult eagerly anticipates your contributions and aims to
                foster enriching dialogues within the vibrant field of literary
                and cultural exploration.
              </p>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default CallForPapersComponent;

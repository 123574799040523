import React from "react";
import HeaderComponent from "../Header/HeaderComponent";
import FooterComponent from "../Footer/FooterComponent";
const ReadMoreComponent = () => {
  return (
    <>
      <HeaderComponent />
      <div class="container-fluid page-header mb-5 py-5">
        <div class="container">
          <h1 class="display-3 text-white mb-3 animated slideInDown">
            LitCult
          </h1>
          <h5 class="text-white mb-3 text-uppercase slideInDown">
            A Journal of Literary and Cultural Discourse
          </h5>
          <p class="fs-5 fw-medium text-white mb-4 pb-2">
            A Bi-annual Peer Reviewed Open Access E-Journal
          </p>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">
                LitCult: A Journal of Literary and Cultural Discourse
              </h1>
              <p className="mb-4">
                It is a Peer-Reviewed Journal of Literary and Cultural
                Discourse. Our bi-annual publication focuses on the dynamic
                realm of literature and culture across the world. Our mission is
                to foster constructive, insightful, and critical discussions
                concerning literary and cultural themes and tendencies. We
                provide a vital platform for talented young scholars in research
                and emerging creative writers in poetry and fiction on a global
                scale. Within the pages of LitCult, you'll find an array of
                content including research articles from contributors of diverse
                nationalities. These articles span various dimensions and genres
                of literature, culture, literary criticism, linguistics, and
                popular culture. Alongside thought-provoking Book Reviews,
                Poems, and Interviews, we offer an inclusive space for both
                empirical and nonempirical explorations of established critical
                perspectives which explore the multifaceted realms of
                literature, culture, film studies, and popular media studies.
              </p>
            </div>
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Journal Particulars</h1>
              <p className="mb-4">
                <table>
                  <tr>
                    <td>Title</td>
                    <td>
                      LitCult: A Journal of Literary and cultural Discourse
                    </td>
                  </tr>
                  <tr>
                    <td>Frequency</td>
                    <td>Biannual</td>
                  </tr>
                  <tr>
                    <td>ISSN</td>
                    <td>Under Process</td>
                  </tr>
                  <tr>
                    <td>Publisher</td>
                    <td>
                      SAKRITI: Society for the Promotion of Literature and
                      Culture
                    </td>
                  </tr>
                  <tr>
                    <td>Editor</td>
                    <td>Dr. Kaptan Singh</td>
                  </tr>
                  <tr>
                    <td>Copyright</td>
                    <td>
                      Authors allowed to republish by acknowledging the first
                      source
                    </td>
                  </tr>
                  <tr>
                    <td>Starting Year</td>
                    <td>2025</td>
                  </tr>
                  <tr>
                    <td>Subject</td>
                    <td>
                      Cultural Studies,Folk Lore, Literary Criticism, Pop
                      Culture and Film Studies
                    </td>
                  </tr>
                  <tr>
                    <td>Language</td>
                    <td>English</td>
                  </tr>
                  <tr>
                    <td>Publication Format&nbsp;&nbsp;&nbsp;&nbsp;</td>
                    <td>
                      Open Journal System (OJS) by Public Knowledge Project PKP
                    </td>
                  </tr>
                  <tr>
                    <td>Email Id</td>
                    <td>litcultajournal@gmail.com</td>
                  </tr>
                  <tr>
                    <td>Mobile Number</td>
                    <td>+918279830418</td>
                  </tr>
                  <tr>
                    <td>Website</td>
                    <td>http://www.litcultjournal.com</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>
                      02, Maharani Bagh, Phase 2, GMS Road, Dehradun,
                      Uttarakhand (248006)
                    </td>
                  </tr>
                </table>
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Submission Deadlines:</h1>
              <p className="mb-4">
                For the March and September editions, we welcome Research
                Papers, Book Reviews, and Poems during the months of November
                and May, respectively. Please note that we retain the right to
                conclude submissions ahead of the announced deadlines. Kindly
                forward your submissions via email to litcultajournal@gmail.com
                . Along with your submission, please affirm its originality,
                stating that it remains unpublished, devoid of any plagiarized
                content, and has not been offered elsewhere. Your submission
                email should include a declaration that you have familiarized
                yourself with our submission guidelines and adhere to the
                journal's policies. In cases where your submissions include
                Quotations in languages other than English, kindly provide an
                English translation within the text and enclose it in
                parentheses. Further details about our peer review process,
                publication ethics, copyrights, and other pertinent information
                can be accessed by clicking on the 'About Us' section.
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Peer Review Process:</h1>
              <p className="mb-4">
                At LitCult, we follow a rigorous double-blind review process to
                ensure anonymity for both reviewers and authors. Within three
                weeks of submission, reviewers provide feedback, which may
                include approval, disapproval, or suggestions for improvement.
                Authors have two weeks to revise and resubmit if needed, for
                further review and possible publication. The procedural flow
                chart is provided below.
              </p>
              <img src="./img/flow.png" width={"100%"} alt="img" />
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Plagiarism Policy:</h1>
              <p className="mb-4">
                At LitCult, we maintain a strict stance against plagiarism. If
                any instance of plagiarism is detected, the Editor retains the
                authority to request the author(s) involved to compose a formal
                letter of apology to the author(s) of the plagiarized work. This
                letter must include an acknowledgment of the act of plagiarism.
                Should the plagiarized paper be published on our website, access
                to it will be promptly revoked. In cases where the paper is
                still under submission, the Editor will enact an automatic
                rejection of the submission. Furthermore, authors identified to
                be engaging in plagiarism will face a permanent ban from our
                platform.
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Author Guidelines:</h1>
              <p className="mb-4">
                All articles and papers should adhere to the guidelines outlined
                in the MLA Handbook for Writers of Research Papers (8th
                Edition). The initial page must encompass the Research Paper's
                Title or Sub-Title, accompanied by a succinct abstract of
                approximately 150 words, along with pertinent Keywords.
                Furthermore, include the Author(s)' Name, Designation, Official
                Address, and Email Address. Manuscripts should be presented in
                Font: Times New Roman, Size: 12, and alignment justified.
                Maintain a single-spaced line spacing throughout the document.
                Submissions are only accepted in MS Word 2007 or 2010 format.
                The length of articles or papers is recommended to be
                approximately 3500 words, inclusive of the Works Cited section.
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Editor</h1>
              <p className="mb-4">
                <h5 className="fw-bold mb-0">Dr. Kaptan Singh</h5>
                <h6
                  style={{
                    color: "rgb(72 111 176)",
                    fontSize: "14px",
                    fontFamily: "Roboto",
                  }}
                  className="fw-bold mb-0"
                >
                  Assistant Professor,
                </h6>
                <h6
                  style={{
                    color: "rgb(72 111 176)",
                    fontSize: "14px",
                    fontFamily: "Roboto",
                  }}
                  className="fw-bold mb-0"
                >
                  Department of English
                </h6>
                <h6
                  style={{
                    color: "rgb(72 111 176)",
                    fontSize: "14px",
                    fontFamily: "Roboto",
                  }}
                  className="fw-bold mb-0"
                >
                  ACC Wing,
                </h6>
                <h6
                  style={{
                    color: "rgb(72 111 176)",
                    fontSize: "14px",
                    fontFamily: "Roboto",
                  }}
                  className="fw-bold mb-0"
                >
                  Indian Military Academy,
                </h6>
                <h6
                  style={{
                    color: "rgb(72 111 176)",
                    fontSize: "14px",
                    fontFamily: "Roboto",
                  }}
                  className="fw-bold mb-0"
                >
                  Dehradun, Uttarakhand 248007,
                </h6>
                <h6
                  style={{
                    color: "rgb(72 111 176)",
                    fontSize: "14px",
                    fontFamily: "Roboto",
                  }}
                  className="fw-bold mb-0"
                >
                  Department of English
                </h6>
                <br />
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Associate Editors</h1>
              <p className="mb-4">
                <ul className="showGridEditors">
                  <li style={{ width: "330px" }}>
                    <h5 className="fw-bold mb-0">
                      Dr. Prithiviraj Singh Chauhan
                    </h5>
                    <h6
                      style={{
                        color: "rgb(72 111 176)",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                      className="fw-bold mb-0"
                    >
                      Assistant Professor,
                    </h6>
                    <h6
                      style={{
                        color: "rgb(72 111 176)",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                      className="fw-bold mb-0"
                    >
                      Department of English
                    </h6>
                    <h6
                      style={{
                        color: "rgb(72 111 176)",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                      className="fw-bold mb-0"
                    >
                      Deva Nagari College,
                    </h6>
                    <h6
                      style={{
                        color: "rgb(72 111 176)",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                      className="fw-bold mb-0"
                    >
                      Meerut, Uttara Pradesh, India
                    </h6>
                  </li>
                  <br />
                  <li>
                    <h5 className="fw-bold mb-0">Dr. B. Padmanabhan</h5>
                    <h6
                      style={{
                        color: "rgb(72 111 176)",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                      className="fw-bold mb-0"
                    >
                      Assistant Professor
                    </h6>
                    <h6
                      style={{
                        color: "rgb(72 111 176)",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                      className="fw-bold mb-0"
                    >
                      Department of English
                    </h6>
                    <h6
                      style={{
                        color: "rgb(72 111 176)",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                      className="fw-bold mb-0"
                    >
                      Bharathiar University,
                    </h6>
                    <h6
                      style={{
                        color: "rgb(72 111 176)",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                      className="fw-bold mb-0"
                    >
                      Coimbatore, Tamil Nadu, India.{" "}
                    </h6>
                  </li>
                </ul>
              </p>

              <p className="mb-4">
                <ul className="showGridEditors">
                  <li style={{ width: "330px" }}>
                    <h5 className="fw-bold mb-0">Dr. Valiur Rahaman</h5>
                    <h6
                      style={{
                        color: "rgb(72 111 176)",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                      className="fw-bold mb-0"
                    >
                      Associate Professor
                    </h6>
                    <h6
                      style={{
                        color: "rgb(72 111 176)",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                      className="fw-bold mb-0"
                    >
                      Lovely Professional University,
                    </h6>
                    <h6
                      style={{
                        color: "rgb(72 111 176)",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                      className="fw-bold mb-0"
                    >
                      Jalandhar, Punjab, India.
                    </h6>
                  </li>
                  <br />
                  <li>
                    <h5 className="fw-bold mb-0">Dr. Ipsita Bhattacharyya</h5>
                    <h6
                      style={{
                        color: "rgb(72 111 176)",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                      className="fw-bold mb-0"
                    >
                      Assistant Professor
                    </h6>
                    <h6
                      style={{
                        color: "rgb(72 111 176)",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                      className="fw-bold mb-0"
                    >
                      Department of English
                    </h6>
                    <h6
                      style={{
                        color: "rgb(72 111 176)",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                      className="fw-bold mb-0"
                    >
                      DHSK Commerce College,
                    </h6>
                    <h6
                      style={{
                        color: "rgb(72 111 176)",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                      className="fw-bold mb-0"
                    >
                      Dibrugarh, Assam, India.
                    </h6>
                  </li>
                </ul>
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Areas of Thrust</h1>
              <p className="mb-4">
                Research Papers on literature, culture, film studies, and
                popular media studies Creative Writings – Poems Book Reviews
                Interviews with Writers
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Contact</h1>
              <p className="mb-4">
                Dr. Kaptan Singh
                <br />
                Editor, LitCult
                <br />
                Assistant Professor
                <br />
                Department of English
                <br />
                ACC Wing
                <br />
                Indian Military Academy
                <br />
                Dehradun, Uttarakhand 248007 <br />
                <a href="mailto:litcultajournal@gmail.com">
                  {" "}
                  litcultajournal@gmail.com
                </a>
                <br />
                +91-8279830418
              </p>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default ReadMoreComponent;

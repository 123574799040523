import React from "react";
import HeaderComponent from "../Header/HeaderComponent";
import FooterComponent from "../Footer/FooterComponent";
const SubmissionComponent = () => {
  return (
    <>
      <HeaderComponent />
      <div className="container-fluid page-header mb-5 py-5">
        <div className="container">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Submission Guidelines
          </h1>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Submission Guidelines - MLA 8th Edition</h1>
              <p className="mb-4">
                To ensure a smooth submission process and maintain uniformity in
                formatting, please adhere to the following guidelines based on
                the MLA 8th Edition style:
              </p>
            </div>
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Manuscript Preparation:</h1>
              <p className="mb-4">
                All submissions must be in English and should be well-written,
                clear, and concise. Use a standard font (e.g., Times New Roman)
                in size 12 and double-spacing throughout the document. Set
                margins to 1 inch (2.54 cm) on all sides.
              </p>
            </div>
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Title Page:</h1>
              <p className="mb-4">
                Include a separate title page with the following information:
                Title of the article: Centered and in title case (capitalize
                major words). Author's name(s): Full name(s) with affiliations
                and email addresses. Abstract: A brief summary of the article's
                content (approximately 150-250 words). Keywords: Provide 3 to 5
                relevant keywords that represent the core themes of your paper
              </p>
            </div>
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">In-text Citations and References:</h1>
              <p className="mb-4">
                Use parenthetical in-text citations to acknowledge sources. For
                example: (Author's Last Name Page Number). Include a Works Cited
                page at the end of your manuscript, listing all cited sources in
                alphabetical order.
              </p>
            </div>
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Citations for Different Types of Sources:</h1>
              <p className="mb-4">
                Book: Author's Last Name, First Name. Title of Book. Publisher,
                Year. Journal Article: Author's Last Name, First Name. "Title of
                Article." Title of Journal, vol. X, no. Y, Year, pp. Page
                Numbers. Website: Author's Last Name, First Name. "Title of
                Webpage." Title of Website, Publisher (if available),
                Publication Date (if available), URL.
              </p>
            </div>
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Length of Submissions:</h1>
              <p className="mb-4">
                Research Articles: 5,000 to 8,000 words (including abstract,
                notes, and references). Book Reviews: 800 to 1,000 words
                (including publication details).
              </p>
            </div>
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Submission Process:</h1>
              <p className="mb-4">
                Please submit your manuscript as a Microsoft Word document (.doc
                or .docx) to litcultajournal@gmail.com. In the email subject
                line, mention "Submission - [Your Paper Title]." Ensure that
                your submission does not contain any identifying information
                about the author(s) in the document to enable blind peer review.
              </p>
            </div>
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Peer Review and Publication:</h1>
              <p className="mb-4">
                All submissions will undergo a rigorous peer review process to
                ensure academic excellence. Our editorial team will provide
                timely feedback on the status of your submission. Upon
                acceptance, you will be asked to sign a copyright transfer
                agreement.
              </p>
            </div>
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Publication Schedule:</h1>
              <p className="mb-4">
                LitCult publishes two issues per year (July-August and
                January-February).
                <br />
                <br />
                Submissions received before the specified deadlines will be
                considered for the corresponding issue.
                <br />
                <br />
                We thank you for considering LitCult as a potential venue for
                your scholarly contributions. Our team of editors looks forward
                to reviewing your work and contributing to the dissemination of
                knowledge in the fields of literature and culture.
                <br />
                <br />
                If you have any questions or require further assistance, please
                feel free to contact us at- litcultajournal@gmail.com <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default SubmissionComponent;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeComponent from './components/Home';
import AboutComponent from './components/About';
import AssociateEditors from './components/AssociateEditors';
import CallForPapers from './components/CallForPapers';
import Contact from './components/Contact';
import EditorAdvisoryBoard from './components/EditorAdvisoryBoard';
import Editors from './components/Editors';
import ReadMore from './components/ReadMore';
import ReviewEditors from './components/ReviewEditors';
import Submission from './components/Submission';
import SubscriptionDetails from './components/SubscriptionDetails';
import UnderProcess from './components/UnderProcess';
import CurrentIssue from './components/CurrentIssue';
import './App.css';
import './bootstrap.min.css'
import Archives from './components/Archives';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeComponent />} />
        <Route path="/about" element={<AboutComponent />} />
        <Route path="/associtateeditors" element={<AssociateEditors />} />
        <Route path="/callforpapers" element={<CallForPapers />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/editoradvisoryboard" element={<EditorAdvisoryBoard />} />
        <Route path="/editors" element={<Editors />} />
        <Route path="/readmore" element={<ReadMore />} />
        <Route path="/revieweditors" element={<ReviewEditors />} />
        <Route path="/submission" element={<Submission />} />
        <Route path="/subscriptiondetails" element={<SubscriptionDetails />} />
        <Route path="/underprocess" element={<UnderProcess />} />
        <Route path="/currentissue" element={<CurrentIssue />} />
        <Route path="/archives" element={<Archives />} />
      </Routes>
    </Router>
  );
};

export default App;

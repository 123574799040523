import React from "react";
import HeaderComponent from "../Header/HeaderComponent";
import FooterComponent from "../Footer/FooterComponent";
const ArchivesComponent = () => {
  return (
    <>
      <HeaderComponent />
      <div className="container-fluid page-header mb-5 py-5">
        <div className="container">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Archives
          </h1>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default ArchivesComponent;

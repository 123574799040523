import React from 'react';
import HeaderComponent from '../Header/HeaderComponent';
import FooterComponent from '../Footer/FooterComponent';
import './Editors.css'
const EditorsComponent = () => {
    
  
    return (
        <>
        <HeaderComponent activeLink={"editorial"}/>
        <div className="container-fluid page-header mb-5 py-5">
        <div className="container">
            <h1 className="display-3 text-white mb-3 animated slideInDown">Editor in Chief</h1>
        </div>
    </div>

    <div className="container-xxl py-5">
  <div
    className="container"
    style={{ display: "flex", justifyContent: "space-between" }}
  >
    <div
      className="text-center wow fadeInUp"
      data-wow-delay="0.1s"
      style={{ display: "flex", justifyContent: "space-around" }}
    ></div>
  </div>
  <div
    className="row g-4"
    style={{ display: "flex", justifyContent: "space-around" }}
  >
    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
      <div className="team-item">
        <div className="position-relative overflow-hidden">
          <img className="img-fluid" style={{ width: "100%" }} src="./img/PicCult.png" alt="" />
        </div>
        <div className="team-text new-team">
          <div className="bg-light-new">
            <h5 className="fw-bold mb-0">Dr. Kaptan Singh</h5>
            <h6
              style={{
                color: "rgb(72, 111, 176)",
                fontSize: "14px",
                fontFamily: "Roboto",
              }}
              className="fw-bold mb-0"
            >
              Assistant Professor
            </h6>
            <h6
              style={{
                color: "rgb(72, 111, 176)",
                fontSize: "14px",
                fontFamily: "Roboto",
              }}
              className="fw-bold mb-0"
            >
              Department of English
            </h6>
            <h6
              style={{
                color: "rgb(72, 111, 176)",
                fontSize: "14px",
                fontFamily: "Roboto",
              }}
              className="fw-bold mb-0"
            >
              ACC, Wing
            </h6>
            <h6
              style={{
                color: "rgb(72, 111, 176)",
                fontSize: "14px",
                fontFamily: "Roboto",
              }}
              className="fw-bold mb-0"
            >
              Indian Military Academy
            </h6>
            <h6
              style={{
                color: "rgb(72, 111, 176)",
                fontSize: "14px",
                fontFamily: "Roboto",
              }}
              className="fw-bold mb-0"
            >
              Dehradun Uttarakhand 248007
            </h6>
            <h6
              style={{
                color: "rgb(72, 111, 176)",
                fontSize: "14px",
                fontFamily: "Roboto",
              }}
              className="fw-bold mb-0"
            >
              Mobile: 8279830418
            </h6>
            <a
              href="mailto:tarankaptan@gmail.com"
              style={{
                color: "rgb(72, 111, 176)",
                fontSize: "14px",
                fontFamily: "Roboto",
              }}
              className="fw-bold mb-0"
            >
              Email: <u>tarankaptan@gmail.com</u>
            </a>
            <div className="h-100 d-inline-flex align-items-center" >
              <a
                className="btn btn-sm-square me-1"
                target="_blank"
                rel='noreferrer'
                href="https://independent.academia.edu/KaptanSingh5"
                style={{ borderRadius: "50%" }}
              >
                <img src="./img/academia.svg" alt="Academia" />
              </a>
              &nbsp;
              <a
                className="btn btn-sm-square me-1"
                target="_blank"
                rel='noreferrer'
                href="https://scholar.google.com/citations?user=OLTHhegAAAAJ&hl=en"
                style={{ borderRadius: "50%", marginRight: "10px" }}
              >
                <img src="./img/scholar.png" alt="Scholar" />
              </a>
              &nbsp;
              <a
                className="btn btn-sm-square bg-white text-primary me-1"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/kaptan-singh-3572a1a1/"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
              &nbsp;
              <a
                className="btn btn-sm-square me-1"
                target="_blank"
                rel='noreferrer'
                href="https://orcid.org/0000-0002-6764-1609"
                style={{ borderRadius: "50%" }}
              >
                <img src="./img/orcid.svg" alt="ORCID" />
              </a>
              &nbsp;
              <a
                className="btn btn-sm-square me-1"
                target="_blank"
                rel='noreferrer'
                href="https://www.researchgate.net/profile/Kaptan-Singh-5"
                style={{ borderRadius: "50%" }}
              >
                <img src="./img/researchgate.svg" alt="ResearchGate" />
              </a>
              &nbsp;
              <a
                className="btn btn-sm-square bg-white text-primary me-1"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/kaptansingh.nanda?mibextid=LQQJ4d"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        <FooterComponent/>
        </>
    );
};

export default EditorsComponent;

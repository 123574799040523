import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import './Header.css';
const HeaderComponent = (props) => {

    const {activeLink} = props;
    const navigate = useNavigate();
    
    const handleClick = (path) => {
      navigate(path);
    };

    const downloadFile = (fileName) => {
        const link = document.createElement("a");
        link.href = `${process.env.PUBLIC_URL}/img/${fileName}`;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
      const [scrollY, setScrollY] = useState(0);
      const [isScrolled, setIsScrolled] = useState(false);
    
      useEffect(() => {
        const handleScroll = () => {
          const currentScrollY = window.scrollY;
    
          // Add shadow and animation on scroll
          if (currentScrollY > 50) {
            setIsScrolled(true);
          } else {
            setIsScrolled(false);
          }
    
          setScrollY(currentScrollY);
        };
    
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
      }, [scrollY]);
  return (
    <>
      <div className="container-fluid bg-light d-lg-block">
        <div className="row align-items-center top-bar">
          <div className="col-lg-3 col-md-12 text-center text-lg-start">
            <a href="/" className="navbar-brand m-0 p-0">
              <img
                src="./img/logo-4.png"
                alt="img"
                style={{ width: "80px", height: "80px", cursor: "pointer" }}
                onClick={() => handleClick("/")}
              />
            </a>
          </div>
          <div className="col-lg-9 col-md-12 text-end">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <i className="far fa-envelope-open text-primary me-2"></i>
              <p className="m-0">
                <a href="mailto:litcultajournal@gmail.com">
                  litcultajournal@gmail.com
                </a>{" "}
              </p>
            </div>
            <div className="h-100 d-inline-flex align-items-center">
              <a
                className="btn btn-sm-square bg-white text-primary me-1"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/people/Litcult-A-Journal/pfbid025ESGTpmTU1yijUuWnu6JwdCGuEVMzsGFzQa3YDh2KK8GFYTBN9v7X4kUSkNsdKBFl/"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                className="btn-sm-square me-1"
                href="/"
                style={{
                  borderRadius: "50%",
                  padding: "15px",
                  marginLeft: "5px",
                }}
              >
                <img src="./img/scholar.png" alt="img" />
              </a>
              &nbsp;
              <a
                className="btn btn-sm-square bg-white text-primary me-1"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/litcult-a-journal-71a8652a1/"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`container-fluid nav-bar bg-light sticky-header ${
        isScrolled ? "scrolled" : ""
      }`}>
        <nav className="navbar navbar-expand-lg navbar-light bg-white p-3 py-lg-0 px-lg-4" style={{cursor: "pointer"}}>
          <a
            href="/"
            className="navbar-brand d-flex align-items-center m-0 p-0 d-lg-none"
          >
            <h1 className="text-primary m-0">LitCult</h1>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav me-auto">
              <span
                className={`nav-item nav-link ${
                  activeLink === "home" ? "active" : ""
                }`}
                onClick={() => handleClick("/")}
              >
                Home
              </span>
              <div className="nav-item dropdown">
                <span
                  className={`nav-link dropdown-toggle ${
                    activeLink === "submission" ? "active" : ""
                  }`}
                  data-bs-toggle="dropdown"
                >
                  Submission
                </span>
                <div className="dropdown-menu fade-up m-0">
                  <span
                    className={`dropdown-item`}
                    onClick={() => handleClick("/callforpapers")}
                  >
                    Call for Papers
                  </span>
                  <span
                    className={`dropdown-item `}
                    onClick={() => handleClick("/submission")}
                  >
                    Submission Guidelines
                  </span>
                  <span
                    className={`dropdown-item`}
                    onClick={() => handleClick("/subscriptionDetails")}
                  >
                    Subscription Details
                  </span>
                  <button
                    className="dropdown-item"
                    onClick={() => downloadFile("copyright.pdf")}
                  >
                    Copyright transfer form (download)
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => downloadFile("declaration.pdf")}
                  >
                    Self Declaration Form (download)
                  </button>
                </div>
              </div>
              <span
                className={`nav-item nav-link ${activeLink === "currentissue" ? "active" : ""}`}
                onClick={() => handleClick("/currentissue")}
              >
                Current Issue
              </span>
              {/* <div className="dropdown-menu fade-up m-0">
                <a href="callforpapers" className="dropdown-item">
                  Inaugural Edition (Call for Papers)
                </a>
              </div> */}
              <div className="nav-item dropdown">
              <span
                className={`nav-item nav-link ${activeLink === "archives" ? "active" : ""}`}
                onClick={() => handleClick("/archives")}
              >
                Archives
              </span>
              </div>
              <span
                className={`nav-item nav-link ${activeLink === "about" ? "active" : ""}`}
                onClick={() => handleClick("/about")}
              >
                About Us
              </span>
              <div className="nav-item dropdown">
              <span
                  className={`nav-link dropdown-toggle ${activeLink === "editorial" ? "active" : ""}`}
                  data-bs-toggle="dropdown"
                >
                  Editorial Board/Reviewers
                </span>
                <div className="dropdown-menu fade-up m-0">
                <span
                    className={`dropdown-item`}
                    onClick={() => handleClick("/editors")}
                  >
                    Editor
                  </span>
                  <span
                    className={`dropdown-item`}
                    onClick={() => handleClick("/associtateeditors")}
                  >
                    Associate Editors
                  </span>
                  <span
                    className={`dropdown-item`}
                    onClick={() => handleClick("/revieweditors")}
                  >
                    Review Editors
                  </span>
                  <span
                    className={`dropdown-item`}
                    onClick={() => handleClick("/editoradvisoryboard")}
                  >
                    Editorial Advisory Board
                  </span>
                </div>
              </div>
              <span
                className={`nav-item nav-link ${activeLink === "contact" ? "active" : ""}`}
                onClick={() => handleClick("/contact")}
              >
                Contact Us
              </span>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default HeaderComponent;

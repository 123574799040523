import React, { useState } from "react";
import HeaderComponent from "../Header/HeaderComponent";
import FooterComponent from "../Footer/FooterComponent";
import axios from "axios";
const ContactComponent = () => {
  const [emailStatus, setEmailStatus] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleEmailSend = (e) => {
    e.preventDefault();
    const payload = {
      name: name,
      email: email,
      subject: subject,
      message: message,
    };
    axios
      .post(
        "https://relaxed-jalebi-18444d.netlify.app/.netlify/functions/api/email",
        { ...payload }
      )
      .then((response) => {
        setEmailStatus(1);
        setTimeout(() => {
          setEmailStatus(0);
        }, 5000);
      })
      .catch((e) => {
        setEmailStatus(-1);
        setTimeout(() => {
          setEmailStatus(0);
        }, 5000);
      });
  };

  return (
    <>
      <HeaderComponent activeLink={"contact"}/>
      <div className="container-fluid page-header mb-5 py-5">
        <div className="container">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Contact Us
          </h1>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <h6 className="text-secondary text-uppercase">Get In Touch</h6>
              <h1 className="mb-4">Contact For Any Query</h1>
              <div className="d-inline-flex align-items-center">
                <a
                  className="btn btn-sm-square text-primary me-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/people/Litcult-A-Journal/pfbid025ESGTpmTU1yijUuWnu6JwdCGuEVMzsGFzQa3YDh2KK8GFYTBN9v7X4kUSkNsdKBFl/"
                >
                  <img src="./img/facebook.png" alt="img" />
                </a>{" "}
                &nbsp;
                <a
                  className="btn btn-sm-square text-primary me-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/"
                >
                  <img src="./img/scholar.png" alt="img" />
                </a>{" "}
                &nbsp;
                <a
                  className="btn btn-sm-square text-primary me-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/litcult-a-journal-71a8652a1/"
                >
                  <img src="./img/linkedin.png" alt="img" />
                </a>{" "}
                &nbsp;
              </div>
              <br />
              <div className="bg-light p-5 d-flex align-items-center">
                <form className="php-email-form">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          id="name"
                          onChange={(e) => {
                            setName(e?.target?.value);
                          }}
                          placeholder="Your Name"
                        />
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          id="email"
                          onChange={(e) => {
                            setEmail(e?.target?.value);
                          }}
                          placeholder="Your Email"
                        />
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          name="subject"
                          className="form-control"
                          onChange={(e) => {
                            setSubject(e?.target?.value);
                          }}
                          id="subject"
                          placeholder="Subject"
                        />
                        <label htmlFor="subject">Subject</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          name="message"
                          onChange={(e) => {
                            setMessage(e?.target?.value);
                          }}
                          placeholder="Leave a message here"
                          id="message"
                          style={{ height: "150px" }}
                        ></textarea>
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      {emailStatus === -1 && (
                        <div className="error-message" style={{ color: "red" }}>
                          {" "}
                          Can not send Email!{" "}
                        </div>
                      )}
                      {emailStatus === 1 && (
                        <div
                          className="sent-message"
                          style={{ color: "green" }}
                        >
                          Your message has been sent. Thank you!
                        </div>
                      )}
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100 py-3"
                        name="submit"
                        onClick={(e) => handleEmailSend(e)}
                        type="submit"
                      >
                        Send E-Mail
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <FooterComponent />
    </>
  );
};

export default ContactComponent;

import React from "react";
import HeaderComponent from "../Header/HeaderComponent";
import FooterComponent from "../Footer/FooterComponent";
import './About.css'
const AboutComponent = () => {
  return (
    <>
      <HeaderComponent activeLink={"about"} />
      <div className="container-fluid page-header mb-5 py-5">
        <div className="container">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            About Us
          </h1>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">
                LitCult: A Journal of Literary and Cultural Discourse
              </h1>
              <p className="mb-4">
                It is a Bi-annual Peer Reviewed Open Access Journal. It is
                dedicated to the exploration and dissemination of literature and
                culture. Our journal provides a platform for academics,
                researchers, and enthusiasts from around the world to contribute
                their unique perspectives and insights to the vibrant fields of
                literature and cultural studies.
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Technical Details</h1>
              <p className="mb-4">
                <table>
                  <tr>
                    <td className="left">Starting Year</td>
                    <td>
                    2025
                    </td>
                  </tr>
                  <tr>
                    <td className="left">Journal ISSN</td>
                    <td>Under Process</td>
                  </tr>
                  <tr>
                    <td className="left">Frequency</td>
                    <td>Biannual (2 Issues/Year: March/September)</td>
                  </tr>
                  <tr>
                    <td className="left">Publishing System</td>
                    <td>
                    Open Journal System (OJS) by Public knowledge Project (PKP)
                    </td>
                  </tr>
                  <tr>
                    <td className="left">Copyright License Type</td>
                    <td>Creative Common Attribution-Non-commercial 4.0 International (CC BY-NC-SA 4.0)</td>
                  </tr>
                </table>
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Our Mission</h1>
              <p className="mb-4">
                At LitCult, our mission is to foster a dynamic and inclusive
                academic community focused on advancing the understanding and
                appreciation of literature and culture. We aim to promote
                interdisciplinary research, critical thinking, and intellectual
                dialogue to enrich the global discourse surrounding these
                diverse and essential subjects.
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Focus Area</h1>
              <p className="mb-4">
                LitCult encompasses a wide range of themes and topics within the
                realms of literature and culture, including but not limited to:
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Literary Theory and Criticism</h1>
              <p className="mb-4">
                Exploring various theoretical approaches and critical analyses
                of literary texts from different periods and genres.
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Cultural Studies</h1>
              <p className="mb-4">
                Investigating cultural practices, traditions, identities, and
                social phenomena that shape our world and influence literature.
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Comparative Literature</h1>
              <p className="mb-4">
                Examining the connections, contrasts, and interactions between
                different literary traditions and cultures.
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Postcolonial Studies</h1>
              <p className="mb-4">
                Analyzing the impact of colonialism and its aftermath on
                literature and cultural expressions.
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Digital Humanities and Literature</h1>
              <p className="mb-4">
                Investigating the intersection of technology and literature,
                including digital storytelling, interactive narratives, and
                virtual realities.
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Gender and Sexuality in Literature</h1>
              <p className="mb-4">
                Exploring the representation and role of gender and sexuality in
                literary works.
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">World Literature</h1>
              <p className="mb-4">
                {" "}
                Delving into the works of writers from diverse linguistic and
                cultural backgrounds, with a focus on global literary
                interactions.
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Publication Schedule</h1>
              <p className="mb-4">
                LitCult publishes two issues annually, in May-June and
                November-December. Our bi-annual release allows us to curate and
                present a rich variety of research articles, reviews, and
                critical essays.
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Open-Access Policy</h1>
              <p className="mb-4">
                We believe in the importance of unrestricted access to
                knowledge. As an open-access journal, all our content is freely
                available to readers worldwide, promoting a broader
                dissemination of research and fostering a more equitable
                academic landscape.
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Peer Review Process</h1>
              <p className="mb-4">
                At LitCult, we maintain a rigorous peer review process to ensure
                the quality and scholarly integrity of the articles we publish.
                Our double-blind review system involves independent experts who
                critically evaluate submissions based on originality, clarity,
                methodology, and contribution to the field.
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Join Us</h1>
              <p className="mb-4">
                We invite scholars, researchers, students, and practitioners to
                join us in our journey of exploring the multifaceted realms of
                literature and culture. Submit your original research, reviews,
                or essays to LitCult and be a part of a global community
                committed to advancing knowledge and understanding in these
                exciting disciplines.
              </p>
              <p className="mb-4">
                For inquiries, submissions, or other information, please contact
                us at litcultajournal@gmail.com.
              </p>
              <p className="mb-4">
                Connect with us on Facebook, LinkedIn to stay updated with the
                latest developments, calls for papers, and noteworthy
                publications.
              </p>
              <p className="mb-4">
                Thank you for being a part of LitCult's intellectual endeavor,
                and we look forward to your valuable contributions and
                readership. Together, let's delve into the captivating world of
                literature and culture. A Journal of literary and Cultural
                Discourse
              </p>
            </div>
            
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default AboutComponent;

import React from "react";
import HeaderComponent from "../Header/HeaderComponent";
import FooterComponent from "../Footer/FooterComponent";
const SubscriptionDetailsComponent = () => {
  return (
    <>
      <HeaderComponent />
      <div className="container-fluid page-header mb-5 py-5">
        <div className="container">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Subscription Details
          </h1>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4">Subscription Details:</h1>
              <p className="mb-4">
                Subscription for the January 2025 issue of the <i>LitCult.</i>{" "}
                <br />
                <br />
                <b style={{ color: "black" }}>Note:</b> No Publication Fee or
                Article Processing Charges.
                <br /> We, as team <i>LitCult</i>, are dedicated to promoting
                quality research and writing, and we believe that excellence
                should not be tied to financial costs. Therefore, we have
                decided not to charge any publication fees or article{" "}
                <i>processing charges</i> for contributors.
              </p>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default SubscriptionDetailsComponent;

import React from "react";
import HeaderComponent from "../Header/HeaderComponent";
import FooterComponent from "../Footer/FooterComponent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./CurrentIssue.css";

const CallForPapersComponent = () => {
  function createData(sno, title, authors, articleType, path) {
    return { sno, title, authors, articleType, path };
  }

  const rows = [
    createData(
      1,
      `The Mythic in the Legendary, and Vice Versa: A Theoretical-Analytical Appraisal of the Kumauni Legend of Haru Singh Heet`,
      `Prof. (Dr.) Prakash Joshi`,
      "Research Article",
      'The-Mythic-in-the-Legendary-and-Vice-Versa-A-Theoretical-Analytical-Appraisal-of-the-Kumauni-Legend-of-Haru-Singh-Heet.pdf'
    ),
    createData(
      2,
      `De-sexualising Identities: Codes of Resistance in Tanuja Desai Hidier’s Born Confused`,
      `Dr Narinder K. Sharma`,
      "Research Article",
      'De-sexualising-Identities-Codes-of-Resistance-in-Tanuja-Desai-Hidier’s-Born-Confused.pdf'
    ),
    createData(
      3,
      `Emotional Labour and Precarious Affect:
Analysing the Affective Manifestations
of Precarious Work as Represented in
Indian Web Series Made in Heaven`,
      `Tanya Kaur,
Prof. Meenu
A. Gupta`,
      "Research Article",
      'Emotional-Labour-and-Precarious-Affect-Analysing-the-Affective-Manifestations-of-Precarious-Work-as-Represented-in-Indian-Web-Series-Made-in-Heaven.pdf'
    ),
    createData(
      4,
      `Discourse, Dis/ability and Act of
Silencing: Re-reading Raghuvir Sahay's
“Camera Me Band Apahij” (The
Handicapped Caged in the Camera)`,
      `Dr Prithiviraj
Singh
Chauchan`,
      "Research Article",
      `Discourse-Disability-and-Act-of-Silencing-Re-reading-Raghuvir-Sahay's-“Camera-Me-Band-Apahij”-The-Handicapped-Caged-in-the-Camera.pdf`
    ),
    createData(
      5,
      `Posthumanism in Suniti Namjoshi’s The
Blue Donkey Fables and The Feminist
Fables`,
      `Dr. Saroj
Kumar`,
      "Research Article",
      'Posthumanism-in-Suniti-Namjoshi’s-The-Blue-Donkey-Fables-and-The-Feminist-Fables.pdf'
    ),
    createData(
      6,
      `Social Ethos and Philosophical
Understanding: A Study of Early
Buddhism and Traditional Order`,
      `Prof. Anup
Kumar
Prof. Om
Prakash`,
      "Research Article",
      'Social-Ethos-and-Philosophical-Understanding-A-Study-of-Early-Buddhism-and-Traditional-Order.pdf'
    ),
    createData(
      7,
      `Beyond Rituals: Application of
Hermeneutics to Make Indian Scriptures
Relevant Today`,
      `Dr. Nitin
Malhotra`,
      "Research Article",
      'Beyond-Rituals-Application-of-Hermeneutics-to-Make-Indian-Scriptures-Relevant-Today.pdf'
    ),
    createData(
      8,
      `Disability, Motherhood, and the Quest
for ‘Self’ in Jaishree Misra’s
Ancient Promises`,
      `Dr. Shanta
Surejya`,
      "Research Article",
      'Disability-Motherhood-and-the-Quest-for-‘Self’-in-Jaishree-Misra’s-Ancient-Promises.pdf'
    ),
    createData(
      9,
      `Unmasking Identity Trauma: The
Cultural Displacement of Afghans in
Khaled Hosseini’s The Kite Runner`,
      `M. Anu & Dr.
S. Boopathi`,
      "Research Article",
      'Unmasking-Identity-Trauma-The-Cultural-Displacement-of-Afghans-in-Khaled-Hosseini’s-The-Kite-Runner.pdf'
    ),
    createData(
      10,
      `Women in Bollywood: From Tropes to
Challenging Stereotype`,
      `Iram Tasleem`,
      "Research Article",
      'Women-in-Bollywood-From-Tropes-to-Challenging-Stereotype.pdf'
    ),
    createData(
      11,
      `Navigating Identity, Queerness, and
Family: A Critical Review of Maggie
Nelson's The Argonauts`,
      `Ankita Saren & Srijani
Ganguly`,
      "Book Review",
      `Navigating-Identity-Queerness-and-Family-A-Critical-Review-of-Maggie-Nelson's-The-Argonauts.pdf`
    ),
    createData(
      12,
      `Two Poems by K. V. Raghupathi`,
      `Prof. K. V.
Raghupathi`,
      "Poetry",
      'Two-Poems-by-K.-V.-Raghupathi-Summer-Rain-and-Wars-will-Never-End.pdf'
    ),
    createData(
      13,
      `Under the Sullen Roots
(A play in five scenes)`,
      `Prof. R.P.
Singh`,
      "Play",
      'Under-the-Sullen-Roots-A-play-in-five-scenes.pdf'
    ),
  ];


  const handleBtnClick = (e, path) => {
    window.open('/LC-01/uploads/2024-11-14/'+path, '_blank');

  }

  return (
    <>
      <HeaderComponent activeLink={"currentissue"} />
      <div className="container-fluid page-header mb-5 py-5">
        <div className="container">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Vol. No. 01, Issue No. 1, 2025
          </h1>
        </div>
      </div>
      <TableContainer className="issueTable">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableHeader">S.No</TableCell>
              <TableCell className="tableHeader">Title</TableCell>
              <TableCell className="tableHeader">Author(s)</TableCell>
              <TableCell className="tableHeader">Article Type</TableCell>
              <TableCell className="tableHeader">Download</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <TableRow
                key={row.sno}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell scope="row">
                  {row.sno}
                </TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.authors}</TableCell>
                <TableCell>{row.articleType}</TableCell>
                <TableCell>
                  <button className="btn btn-primary" onClick={(e)=>handleBtnClick(e, row.path)} type="button">
                    View & Download
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FooterComponent />
    </>
  );
};

export default CallForPapersComponent;

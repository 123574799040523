import React from "react";
import { useNavigate } from "react-router-dom";

import "./Footer.css";
const FooterComponent = () => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };
  return (
    <>
      <div
        className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container">
          <div className="row mt-4">
            <div className="col-md-3">
              <img
                src="./img/logo-4.png"
                alt="img"
                style={{ width: "120px", position: "relative", left: "15px" }}
              />
              <p>
                A Journal of Literary
                <br /> and Cultural Discourse
              </p>
            </div>
            <div className="col-md-3 text-md">
              <h5 className="mb-4 headings">About LitCult</h5>
              <p>
                A double-blind, peer-reviewed open access e-journal dedicated
                for the promotion of literature and culture.
              </p>
            </div>
            <div className="col-md-3 text-md">
              <h5 className="mb-4 headings">Quick Links</h5>
              <span
                onClick={(e) => handleClick("/currentissue")}
                style={{ color: "goldenrod" }}
              >
                Current Issue
              </span>
            </div>
            <div className="col-md-3 text-md">
              <h5 className="mb-4 headings">Address</h5>
              <p>
                02, Maharani Bagh, Phase 2, GMS Road, Dehradun, Uttarakhand
                (248006)
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "70px",
          backgroundColor: "#003366",
          color: "white",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center"
        }}
      >
        <div>
          Copyright
          &copy;{" "} 2025
          LitCult: A Journal of Literary and Cultural Discourse 
          {/* <a className="border-bottom text-light" href="/">
            litcultjournal.com
          </a> */}
        </div>
        <h4 className="text-light" style={{fontWeight:"200"}}>
          Visitors: &nbsp;
            <a
              href="https://www.hitwebcounter.com"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://hitwebcounter.com/counter/counter.php?page=9061703&style=0006&nbdigits=7&type=page&initCount=0"
                title="Counter Widget"
                alt="Visit counter For Websites"
                border="0"
              />
            </a>
        </h4>
      </div>
      <button
        className="btn btn-lg btn-primary btn-lg-square rounded-0 back-to-top"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        aria-label="Back to top"
      >
        <i className="bi bi-arrow-up"></i>
      </button>
    </>
  );
};

export default FooterComponent;

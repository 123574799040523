import React from "react";
import HeaderComponent from "../Header/HeaderComponent";
import FooterComponent from "../Footer/FooterComponent";
const EditorAdvisoryComponent = () => {
  const teamData = [
    {
      name: "Dr. Opinderjit Kaur Takhar",
      position: "Associate Professor",
      department: "Department of Religious Studies",
      university: "University of Wolverhampton",
      location: "West Midlands, England, United Kingdom",
      imageSrc: "./img/editors/opinderjit.jpg",
    },
    {
      name: "Prof. Krishna Mohan Pandey",
      position: "Professor",
      department: "Department of English",
      university: "Banaras Hindu University",
      location: "Varanasi, Uttara Pradesh, India",
      imageSrc: "./img/editors/Krishna.jpg",
    },
    {
      name: "Prof. Sumitra Kukreti",
      position: "Pro-Vice Chancellor",
      department: "Indira Gandhi National Open University",
      university: "IGNOU",
      location: "New Delhi, India",
      imageSrc: "./img/editors/sumitra.jpg",
    },
    {
      name: "Prof. Md. Rizwan Khan",
      position: "Professor",
      department: "Department of English",
      university: "Aligarh Muslim University",
      location: "Aligarh, Uttar Pradesh, India",
      imageSrc: "./img/editors/rizwan.jpg",
    },
    {
      name: "Dr. T. Ravichandran",
      position: "Professor of English",
      department: "Department of Humanities and Social Sciences",
      university: "Indian Institute of Technology Kanpur",
      location: "Kanpur, Uttar Pradesh, India",
      imageSrc: "./img/editors/ravi.jpg",
    },
    {
      name: "Prof. Sanjit Kumar Mishra",
      position: "Professor of English",
      department: "Department of Humanities and Social Sciences",
      university: "Indian Institute of Technology Roorkee",
      location: "Uttarakhand, India",
      imageSrc: "./img/editors/sanjit.jpg",
    },
    {
      name: "Dr. R. P. Singh",
      position: "Professor of English",
      department: "Department of English and Modern European Languages",
      university: "University of Lucknow",
      location: "U.P., India",
      imageSrc: "./img/editors/R_p.jpg",
    },
    {
      name: "Dr. Prakash Joshi",
      position: "Professor of English",
      department: "Department of English and Modern European Languages",
      university: "University of Allahabad",
      location: "Prayagraj, India",
      imageSrc: "./img/editors/prakash1.jpeg",
    },
    {
      name: "Dr. Ajay K Chaubey",
      position: "Associate Professor & Head",
      department: "Department of English",
      university: "Jananayak Chandrashekhar University (JNCU)",
      location: "Ballia, Uttar Pradesh, India",
      imageSrc: "./img/editors/AjayChaubey.jpg",
    },
    {
      name: "Prof. Ruby Gupta",
      position: "Professor of Humanities",
      department: "ACC Wing, Indian Military Academy",
      university: "Indian Military Academy",
      location: "Dehradun, Uttarakhand, India",
      imageSrc: "./img/editors/ruby.jpg",
    },
    {
      name: "Prof. Ajay Kumar Shukla",
      position: "Professor",
      department: "Department of English & Modern European Languages",
      university: "DDU Gorakhpur University",
      location: "Gorakhpur, Uttar Pradesh, India",
      imageSrc: "./img/editors/ajay.jpg",
    },
    {
      name: "Prof. Atanu Bhattacharya",
      position: "Professor",
      department: "Centre for English Studies",
      university: "Central University of Gujarat",
      location: "Gandhinagar, Gujarat, India",
      imageSrc: "./img/editors/atanu.jpg",
    },
    {
      name: "Prof. Ujjwal Jana",
      position: "Professor",
      department: "Department of English",
      university: "University of Delhi",
      location: "New Delhi, India",
      imageSrc: "./img/editors/ujjwal.jpg",
    },
    {
      name: "Prof. Meenu Aggarwal Gupta",
      position: "Professor",
      department: "Department of English & Cultural Studies",
      university: "Panjab University,",
      location: "Chandigarh, India.",
      imageSrc: "./img/editors/meenu.jpg",
    },
    {
        name: "Prof. T Marx",
        position: "Professor",
        department: "Department of English",
        university: "Pondicherry University,",
        location: "Puducherry, India.",
        imageSrc: "./img/editors/t marx.jpg",
    },
    {
        name: "Prof. Sushil Kumar",
        position: "Professor",
        department: "Department of English",
        university: "Punjabi University",
        location: "Guru Kashi Campus",
        extra:"Damdama Sahib, Sabo, Punjab, India",
        imageSrc: "./img/editors/sushil.jpg",
    },
    {
        name: "Dr. H. S. Randhawa",
        position: "Professor",
        department: "Department of English",
        university: "DAV (PG) College,",
        location: "Dehradun, Uttarakhand, India.",
        imageSrc: "./img/editors/randhawa.jpg",
    }
  ];

  return (
    <>
      <HeaderComponent activeLink={"editorial"}/>
      <div className="container-fluid page-header mb-5 py-5">
        <div className="container">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Editorial Advisory Board
          </h1>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div
          className="container"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="row g-4" style={{ display: "flex" }}>
            {teamData.map((member, index) => (
              <div
                key={index}
                className="col-lg-3 col-md-6 wow fadeInUp showGrid"
                data-wow-delay="0.1s"
              >
                <div className="team-item list">
                  <div className="position-relative overflow-hidden">
                    <img
                      className="img-fluid imageHeightWidth"
                      src={member.imageSrc}
                      alt={`pic`}
                    />
                  </div>
                  <div className="team-text new-team">
                    <div className="bg-light-new">
                      <h5 className="fw-bold mb-0">{member.name}</h5>

                      <h6
                        key={index}
                        style={{
                          color: "rgb(72, 111, 176)",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                        }}
                        className="fw-bold mb-0"
                      >
                        {member.department}
                        <br />
                        {member.university}
                        <br />
                        {member.location}
                        {
                            member?.extra && <><br/>{member.extra}</> 
                        }
                      </h6>
                    </div>
                  </div>
                </div>{" "}
              </div>
            ))}
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default EditorAdvisoryComponent;
